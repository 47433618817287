<template>
    <div class="page iphone-x-adaptation">
        <div class="adress" v-if="pageType == 0 && !addressId">
            <div class="kfc-name">{{ mealStoreInfo.name }}</div>
            <div class="adress-detail">{{ mealStoreInfo.adress }}</div>
            <div class="tips">注:约5分钟后取餐，请尽快到餐厅取餐哦</div>
        </div>
        <div class="address-box" v-if="pageType == 1 || (pageType == 0 && addressId)">
            <div class="address-info">
                <div class="street">
                    {{ addressInfo.cityName }} {{ addressInfo.regionName }}
                    {{ addressInfo.mainAddress }}{{ addressInfo.detailAddress }}
                </div>
                <div class="user">{{ addressInfo.name }} {{ addressInfo.mobile }}</div>
            </div>
            <div class="msg-line">
                <div class="name">立即送出</div>
                <div class="value">约{{ outByTime }}送达</div>
            </div>
        </div>
        <div class="address-box" v-if="pageType == 2 && !addressId">
            <div class="address-info" @click="toSwitchAddress">
                <div class="tip-1">您还没有设置收货地址</div>
                <div class="tip-2">请点击前往设置</div>
                <div class="icon-right"></div>
            </div>
        </div>
        <div class="address-box" v-if="pageType == 2 && addressId">
            <div class="address-info" @click="toSwitchAddress">
                <div class="street">
                    {{ addressInfo.cityName }} {{ addressInfo.regionName }}
                    {{ addressInfo.mainAddress }}{{ addressInfo.detailAddress }}
                </div>
                <div class="user">{{ addressInfo.name }} {{ addressInfo.mobile }}</div>
            </div>
            <div class="msg-line"></div>
        </div>
        <div class="order-detail box-shadow">
            <div class="name">
                {{ pageType == 0 && addressId ? mealStoreInfo.name : '餐品详情' }}
            </div>
            <div class="list">
                <template v-for="(item, index) in onLineShopCardList">
                    <div
                        :key="index"
                        v-if="item.quantity > 0"
                        class="order-single"
                        :class="[
                            { favour: 5 == item.activityType },
                            { voucher: 10 == item.activityType },
                        ]"
                    >
                        <img :src="item.productImageUrl" class="img" />
                        <div class="title">
                            <p class="p">{{ item.productName }}</p>
                        </div>
                        <div class="price">{{ item.salePrice }}</div>
                        <span class="original-price">¥{{ item.originPrice }}</span>
                        <span class="num">x{{ item.quantity }}</span>
                    </div>
                </template>
            </div>
            <!-- <div class="list" v-else>
                <template v-for="(item, index) in mealDetail">
                    <template v-if="item.type == 1 || item.type == 2 || item.type == 3">
                        <div
                            :key="index"
                            class="order-single"
                            :class="{
                                'has-sku':
                                    item.condimentItemList && item.condimentItemList.length > 0,
                            }"
                        >
                            <img :src="item.productImageUrl" class="img" />
                            <div class="title">{{ item.productName }}</div>
                            <div
                                class="sku-box"
                                :class="{ open: item.skuOpenStatus }"
                                v-if="item.condimentItemList && item.condimentItemList.length"
                                @click="openSkuText(index)"
                            >
                                <div
                                    class="sku-item"
                                    v-for="(v, i) in item.condimentItemList"
                                    :key="i"
                                >
                                    <span class="sku-product-name">{{ v.productName }}</span>
                                    <span class="sku-product-count">* {{ v.count }}</span>
                                </div>
                            </div>
                            <div class="price">{{ item.price }}</div>
                            <span class="original-price">¥{{ item.originalPrice }}</span>
                            <span class="num">x{{ item.num }}</span>
                        </div>
                    </template>
                    <template v-else-if="!item.showReduceBtn">
                        <div
                            v-for="v in item.items"
                            :key="v.productId"
                            v-if="v.num > 0"
                            class="order-single"
                        >
                            <img :src="v.productImageUrl" class="img" />
                            <div class="title">{{ v.productName }}</div>
                            <div class="price">{{ v.price }}</div>
                            <span class="original-price">
                                ¥{{ v.originalPrice }}
                                <span class="first-activity" v-if="v.firstActivity">
                                    每天前{{ v.firstActivity.activityCount }}件 ¥{{
                                        v.firstActivity.price
                                    }}
                                </span>
                            </span>
                            <span class="num">x{{ v.num }}</span>
                        </div>
                    </template>
                    <template v-else>
                        <div class="order-single" :key="item.productId">
                            <img :src="item.productImageUrl" class="img" />
                            <div class="title">{{ item.productName }}</div>
                            <div class="price">{{ item.real_price }}</div>
                            <span class="original-price">
                                ¥{{ item.originalTotalPrice }}
                                <span class="first-activity" v-if="item.firstActivity">
                                    每天前{{ item.firstActivity.activityCount }}件¥{{
                                        item.firstActivity.price
                                    }}
                                </span>
                            </span>
                            <span class="num">x{{ item.num }}</span>
                        </div>
                    </template>
                </template>
            </div> -->
            <div class="coupon-line">
                <div class="title">优惠券</div>
                <div class="value">
                    <div
                        class="tip-used-coupon"
                        v-if="useCardCount > 0"
                        @click="(couponCardDialogStatus = true) && (couponCardDialogTabIndex = 0)"
                    >
                        -{{ useCardReduceAmount }}
                    </div>
                    <div
                        class="tip-use-coupon"
                        v-else-if="cardCount > 0"
                        @click="(couponCardDialogStatus = true) && (couponCardDialogTabIndex = 0)"
                    >
                        有{{ cardCount }}张可用
                    </div>
                    <div class="tip-not-coupon" v-else>暂无优惠券</div>
                    <div class="right"></div>
                </div>
            </div>
            <div class="coupon-line">
                <div class="title">代金券</div>
                <div class="value">
                    <div
                        class="tip-used-coupon"
                        v-if="useVoucherCount > 0"
                        @click="(couponCardDialogStatus = true) && (couponCardDialogTabIndex = 1)"
                    >
                        -{{ useVoucherReduceAmount }}
                    </div>
                    <div
                        class="tip-use-coupon"
                        v-else-if="voucherCount > 0"
                        @click="(couponCardDialogStatus = true) && (couponCardDialogTabIndex = 1)"
                    >
                        有{{ voucherCount }}张可用
                    </div>
                    <div class="tip-not-coupon" v-else>暂无代金券</div>
                    <div class="right"></div>
                </div>
            </div>
            <div class="sum">
                <div class="line">
                    <template v-if="$store.getters.getExposeMethod.showOriginalPrice">
                        <span>共{{ totalCount }}件</span>
                        <span class="m-l-20">实付</span>
                        <span class="num m-l-20">{{ originalTotal }}</span>
                        ，
                        <span>下单后返</span>
                        <span class="num m-l-20">{{ submitProvince }}</span>
                    </template>
                    <template v-else>
                        <span>共{{ totalCount }}件</span>
                        <span class="m-l-20">小计：</span>
                        <span class="num">{{ submitTotal }}</span>
                    </template>
                </div>
                <div class="line" v-if="xiaoBuDianIntegral">
                    积分：
                    <span class="integral">{{ xiaoBuDianIntegral }}</span>
                </div>
                <div class="line" v-else-if="jiMiWuXianIntegral">
                    能量：
                    <span class="integral">{{ jiMiWuXianIntegral }}</span>
                </div>
            </div>
        </div>
        <!-- 加购区 -->
        <div class="order-detail special box-shadow" v-if="specialMaxQuantity">
            <div class="name">本单可享优惠加购特权 (最多可添加{{ specialMaxQuantity }}份商品)</div>
            <div class="list">
                <div
                    class="order-single"
                    v-for="(item, index) in specialProductList"
                    v-if="(openSpcialStatus ? maxShowSpcialNum : minShowSpcialNum) > index"
                    :key="index"
                >
                    <img class="img" :src="item.productImageUrl" alt="" />
                    <div class="title">{{ item.productName }}</div>
                    <div class="price">
                        {{ item.price }}
                        <span class="original-price">{{ item.originalPrice }}</span>
                    </div>
                    <div class="reduced" v-if="item.reducedPrice">
                        已减{{ item.reducedPrice }}元
                    </div>
                    <div class="icon-click">
                        <img
                            class="btn-reduce"
                            src="@/assets/icons/icon-circular-white-reduce.png"
                            v-if="item.num && item.num > 0"
                            @click="reduceNum(index)"
                        />
                        <div class="quantity" v-if="item.num && item.num > 0">{{ item.num }}</div>
                        <img
                            class="btn-plus"
                            :class="{ 'no-add': specialTotalCount >= specialMaxQuantity }"
                            src="@/assets/icons/icon-circular-plus.png"
                            @click="plusNum(index)"
                        />
                    </div>
                </div>
            </div>
            <div
                class="open-more-spcial"
                v-if="specialProductList.length > minShowSpcialNum"
                :class="{ open: openSpcialStatus }"
            >
                <span @click="switchStatus">{{ openSpcialStatus ? '收起' : '展开全部优惠' }}</span>
            </div>
            <div class="sum" v-if="specialTotalCount">
                <div class="line">
                    <span>共{{ specialTotalCount }}件</span>
                    <span class="m-l-20">小计：</span>
                    <span class="num">{{ specialTotalPay }}</span>
                </div>
            </div>
        </div>
        <div class="user-mobile-line box-shadow" v-if="pageType == 2 || !addressId ">
            <div class="head">联系号码</div>
            <input
                class="user-moblie"
                type="text"
                v-model="userMobile"
                placeholder="请输入联系人手机号"
                maxlength="11"
            />
        </div>
        <div class="meal-type box-shadow" v-if="pageType == 2 || !addressId">
            <div class="head">就餐方式</div>
            <div class="block">
                <div class="btn store-in" :class="{ cur: mealType == 1 }" @click="chooseType(1)">
                    <img class="img" src="@/assets/icons/icon-store-in.png" />
                    <span class="text">店内就餐</span>
                    <img class="select" src="@/assets/icons/icon-order-chose.png" />
                </div>
                <div class="btn store-out" :class="{ cur: mealType == 2 }" @click="chooseType(2)">
                    <img class="img" src="@/assets/icons/icon-store-out.png" />
                    <span class="text">打包带走</span>
                    <img class="select" src="@/assets/icons/icon-order-chose.png" />
                </div>
            </div>
        </div>
        <div class="user-remark box-shadow" @click="userRemarkDialogStatus = true">
            <div class="head">备注</div>
            <div class="value">{{ userRemark || '口味、偏好等要求' }}</div>
            <div class="right"></div>
        </div>
        <div class="padding-bottom"></div>

        <div class="cart-box iphone-x-adaptation open-cart">
            <div class="cart-line">
                <div class="cart-price">
                    <div class="content">
                        <div class="price-box">
                            <div class="price">
                                <div class="text">
                                    ¥{{
                                        $store.getters.getExposeMethod.showOriginalPrice
                                            ? originalTotal
                                            : submitTotal
                                    }}
                                </div>
                                <div class="monery-discount" v-if="xiaoBuDianIntegral">
                                    +{{ xiaoBuDianIntegral }}积分
                                </div>
                                <div class="monery-discount" v-else-if="jiMiWuXianIntegral">
                                    +{{ jiMiWuXianIntegral }}能量
                                </div>
                                <div
                                    class="monery-discount"
                                    v-else-if="
                                        submitProvince > 0 &&
                                        !$store.getters.getExposeMethod.showOriginalPrice
                                    "
                                >
                                    已省¥{{ submitProvince }}元
                                </div>
                            </div>
                            <template v-if="pageType == 0 && addressId">
                                <div class="delivery-fee">
                                    另加配送费
                                    {{ mtDeliveryFee }} 元
                                </div>
                            </template>
                            <template
                                v-if="
                                    $store.state.common.config.kfcTakeoutDeliveryFee == 0 &&
                                    pageType == 1
                                "
                            >
                                <div class="delivery-fee">
                                    免配送费
                                    <span class="line-through">
                                        另加配送费 {{ QZ_KFC_DEFAULT_DELIVERY_FEE }} 元
                                    </span>
                                </div>
                            </template>
                            <template
                                v-else-if="
                                    $store.state.common.config.kfcTakeoutDeliveryFee >=
                                        QZ_KFC_DEFAULT_DELIVERY_FEE && pageType == 1
                                "
                            >
                                <div class="delivery-fee">
                                    另加配送费
                                    {{ $store.state.common.config.kfcTakeoutDeliveryFee }} 元
                                </div>
                            </template>
                            <template v-else-if="pageType == 1">
                                <div class="delivery-fee">
                                    另加配送费
                                    {{ $store.state.common.config.kfcTakeoutDeliveryFee }} 元
                                    <span class="line-through">
                                        {{ QZ_KFC_DEFAULT_DELIVERY_FEE }}元
                                    </span>
                                </div>
                            </template>
                        </div>
                        <div class="submit-btn" @click="addCommit">
                            <div class="title">提交订单</div>
                            <div class="tip">Checkout</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="fix-lary" v-if="dialogShow">
            <div class="fix-win">
                <template v-if="addressId && pageType == 0">
                    <div class="head">
                        <div class="title">核对配送信息</div>
                        <div class="circle"></div>
                    </div>
                    <div class="shop-name">{{ addressInfo.name }} {{ addressInfo.mobile }}</div>
                    <div class="shop-address">
                        {{ addressInfo.mainAddress }} {{ addressInfo.detailAddress }}
                    </div>
                    <div class="tip">
                        <span class="important">温馨提示</span>
                        ：三方代下单，可能出现门店缺餐，如餐品80%以上有货，将会正常配送。缺失餐品实时退款。
                        <span class="important">下单成功后无法退款，请确认后下单。</span>
                        <span
                            class="important"
                            v-if="
                                ($store.getters.getExposeMethod.showOriginalPrice
                                    ? originalTotal
                                    : submitTotal) == 0 && onLineShopCardList.length > 0
                            "
                        >
                            由于您的订单为0元订单，立即支付后将为您下单。
                        </span>
                    </div>
                    <div class="btn-line">
                        <div class="btn" @click="closeConfirm">再想一想</div>
                        <div class="btn pay" @click="debounceSubmitOrder">立即支付</div>
                    </div>
                </template>
                <template v-else>
                    <div class="head">
                        <div class="title">请核对地址</div>
                        <div class="circle"></div>
                    </div>
                    <div class="shop-name">{{ mealStoreInfo.name }}</div>
                    <div class="shop-address">{{ mealStoreInfo.adress }}</div>
                    <div class="tip">
                        提示：请确认取餐地址是否正确，稍后可在【我的订单】查看订单状态，请耐心等待。
                        <span
                            class="important"
                            v-if="
                                ($store.getters.getExposeMethod.showOriginalPrice
                                    ? originalTotal
                                    : submitTotal) == 0 && onLineShopCardList.length > 0
                            "
                        >
                            由于您的订单为0元订单，立即支付后将为您下单。
                        </span>
                    </div>
                    <div class="btn-line">
                        <div class="btn" @click="closeConfirm">再想一想</div>
                        <div class="btn pay" @click="debounceSubmitOrder">立即支付</div>
                    </div>
                </template>
            </div>
        </div>
        <div
            class="coupon-card-dialog"
            @click="couponCardDialogStatus = false"
            v-if="couponCardDialogStatus"
        >
            <div class="content" @click.stop>
                <div class="title">卡券优惠</div>
                <div class="close" @click="couponCardDialogStatus = false"></div>
                <div class="coupon-card">
                    <CouponCard
                        :componentType="2"
                        :setTabIndex.sync="couponCardDialogTabIndex"
                        :takeout="takeout"
                        :addressId="addressId"
                        :setStoreCode="storeCode"
                        @callback="couponUseCallback"
                    ></CouponCard>
                </div>
            </div>
        </div>
        <div
            class="user-remark-dialog"
            @click="userRemarkDialogStatus = false"
            v-if="userRemarkDialogStatus"
        >
            <div class="content iphone-x-adaptation" @click.stop>
                <div class="title">备注</div>
                <div class="close" @click="userRemarkDialogStatus = false"></div>
                <div class="textarea-box">
                    <textarea
                        class="textarea"
                        placeholder="请输入口味、偏好等要求，不支持额外代购其他物品哦～"
                        rows="3"
                        maxlength="50"
                        v-model="tempUserRemark"
                    ></textarea>
                    <div class="count">{{ tempUserRemark.length }}/50</div>
                </div>
                <div class="quick-box">
                    <div class="quick-box-title">快捷标签</div>
                    <div class="quick-list">
                        <div class="quick-item" @click="addUserRemark('去冰')">去冰</div>
                        <div class="quick-item" @click="addUserRemark('少冰')">少冰</div>
                        <div class="quick-item" @click="addUserRemark('老北京不加葱')">
                            老北京不加葱
                        </div>
                        <div class="quick-item" @click="addUserRemark('汉堡不加酱')">
                            汉堡不加酱
                        </div>
                    </div>
                </div>
                <div class="btn-save" @click="saveUserRemark">保存</div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    GET_STOP_INFO,
    CREATRE_KFC_ORDER,
    CREATRE_OUT_BY_KFC_ORDER,
    GET_KFC_SPECIAL_PRODUCT,
    GET_ADDRESS_ITEM,
    GET_LAST_ORDER_MOBILE,
    GET_CART_LIST,
    GET_USER_AVAILABLE_CARD_COUNT,
    GET_DELIVERY_FEE,
} from 'api';
import { PAGE_ORDER_INFO, PAGE_ADDRESS_LIST } from 'page';
import { QZ_KFC_CART_NAME, QZ_ADDRESS_SAVE_ID, QZ_KFC_DEFAULT_DELIVERY_FEE } from 'constant';
import CouponCard from '@/views/kfc/card/index.vue';
import number from '@/lib/number';
import pay from 'pay';
import moment from 'moment';
const mobileReg = /^1[3-9]{1}[0-9]{9}$/;
export default {
    data() {
        return {
            pageType: 0,
            addressId: null,
            addressInfo: {},
            isHasSelf: false,
            dialogShow: false,
            mealType: 1, //类型
            mealStoreInfo: {
                name: '',
                adress: '',
            },
            totalCount: 0, //总数量
            totalPay: 0, //总金额
            province: 0, // 省钱金额
            storeCode: '', //商店编号
            mealDetail: [],
            mealQuest: {
                type1: false,
                // type2: false
            },
            specialMaxQuantity: 0,
            specialProductList: [],
            specialTotalCount: 0, // 加购总数量
            specialTotalPay: 0, // 加购总金额
            specialProvince: 0, // 加购省钱金额
            minShowSpcialNum: 3,
            maxShowSpcialNum: 5,
            openSpcialStatus: false,
            userMobile: '',
            onLineShopCardList: '', // 购物车信息
            orderDetail: null,
            cardCount: 0,
            useCardCount: 0,
            useCardReduceAmount: 0,
            voucherCount: 0,
            useVoucherCount: 0,
            useVoucherReduceAmount: 0,
            couponCardDialogStatus: false,
            couponCardDialogTabIndex: 0,
            userRemarkDialogStatus: false,
            userRemark: '',
            tempUserRemark: '',
            QZ_KFC_DEFAULT_DELIVERY_FEE: QZ_KFC_DEFAULT_DELIVERY_FEE,
            mtDeliveryFee: 0,
        };
    },
    components: {
        CouponCard,
    },
    computed: {
        originalTotal() {
            return number.operation(`${this.submitTotal}+${this.submitProvince}`);
        },
        submitTotal() {
            return number.operation(`${this.totalPay}+${this.specialTotalPay}`);
        },
        submitProvince() {
            return number.operation(`${this.province}+${this.specialProvince}`);
        },
        xiaoBuDianIntegral() {
            if (this.$store.getters.getExposeMethod.customizedXiaoBuDian) {
                return +number.operation(`${this.submitProvince}*20`);
            } else {
                return 0;
            }
        },
        jiMiWuXianIntegral() {
            if (this.$store.getters.getExposeMethod.customizedJiMiWuXian) {
                return +number.operation(`${this.submitProvince}*10`);
            } else {
                return 0;
            }
        },
        outByTime() {
            if (this.pageType == 0 && this.addressId && this.addressId != 0) {
                return `30～60分钟`;
                // return `${moment().add(30, 'm').format('HH:mm')}~${moment().add(60, 'm').format('HH:mm')}`;
            } else if (this.pageType == 1 && this.addressId && this.addressId != 0) {
                return moment().add(40, 'm').format('HH:mm');
            }
            return '';
        },
        originalTotalHasFee() {
            return this.pageType == 1
                ? number.operation(
                      `${this.originalTotal}+${this.$store.state.common.config.kfcTakeoutDeliveryFee}`
                  )
                : this.originalTotal;
        },
        takeout() {
            return this.pageType == 1;
        },
        // originalPrice() {
        //     return this.pageType == 1 ? number.operation(`${this.priceData.originalPrice}+${this.$store.state.common.config.kfcTakeoutDeliveryFee}`) : this.priceData.originalPrice
        // }
    },
    created() {
        this.pageType = this.$route.query.type || 0;
        this.storeCode = this.$route.query.storeCode || 0;
        this.addressId =
            this.$route.query.addressId || window.localStorage.getItem(QZ_ADDRESS_SAVE_ID) || 0;
        window.localStorage.removeItem(QZ_ADDRESS_SAVE_ID);
        // let selectProductList = JSON.parse(localStorage.getItem(QZ_KFC_CART_NAME));
        let totalCount = 0;
        let totalPay = 0;
        let province = 0;
        let isHasSelf = false;
        // for (let i = 0; i < selectProductList.length; i++) {
        //     const item = selectProductList[i];
        //     let tempProductTotal = number.operation(`${item.num}*${item.price}`);
        //     totalPay = number.operation(`${totalPay}+${tempProductTotal}`);
        //     totalCount = number.operation(`${item.num}+${totalCount}`);

        //     var itemProvince = number.operation(`${item.num}*${item.reduce_price}`);
        //     province = number.operation(`${itemProvince}+${province}`);
        // }
        // this.mealDetail = selectProductList;
        this.isHasSelf = isHasSelf;
        this.totalCount = totalCount;
        this.totalPay = (+totalPay).toFixed(2);
        this.province = province;
        if (this.pageType == 0) {
            this.getUserMobile();
            this.getData();
            this.getSpecial();
        }
        if (this.addressId && this.addressId != 0) {
            this.getAddress();
        }
        if (this.pageType == 0 && this.addressId && this.addressId != 0) {
            this.getDeliveryFee();
        }
        this.getOnLineCart();
        this.getCardCount();
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.orderDetail = null;
        });
    },
    methods: {
        // 获取购物车信息
        getOnLineCart() {
            this.$util
                .get(GET_CART_LIST, {
                    storeCode: this.storeCode,
                    takeout: this.takeout,
                })
                .then(res => {
                    if (res.success) {
                        if (res.data.cart.items.length == 0) {
                            this.$util.pageBack();
                        }

                        if (this.$store.getters.getExposeMethod.customizedXiaoBuDian) {
                            for (let i = 0; i < res.data.cart.items.length; i++) {
                                const item = res.data.cart.items[i];
                                let reduce = number.operation(
                                    `${item.originPrice}-${item.salePrice}`
                                );
                                item.xiaoBuDianIntegral = +number.operation(`${reduce}*20`);
                            }
                        }
                        if (this.$store.getters.getExposeMethod.customizedJiMiWuXian) {
                            for (let i = 0; i < res.data.cart.items.length; i++) {
                                const item = res.data.cart.items[i];
                                let reduce = number.operation(
                                    `${item.originPrice}-${item.salePrice}`
                                );
                                item.jiMiWuXianIntegral = +number.operation(`${reduce}*10`);
                            }
                        }
                        let tempUseCardCount = 0;
                        let tempUseCardReduceAmount = 0;
                        let tempUseVoucherCount = 0;
                        let tempUseVoucherReduceAmount = 0;
                        for (let i = 0; i < res.data.cart.items.length; i++) {
                            const item = res.data.cart.items[i];
                            if (5 == item.activityType) {
                                tempUseCardCount += item.quantity;
                                let tempPrice = number.operation(
                                    `${item.originPrice}-${item.salePrice}`
                                );
                                let tempTotal = number.operation(`${tempPrice}*${item.quantity}`);
                                tempUseCardReduceAmount = +number.operation(
                                    `${tempUseCardReduceAmount}+${tempTotal}`
                                );
                            } else if (10 == item.activityType) {
                                tempUseVoucherCount += item.quantity;
                                let tempPrice = number.operation(
                                    `${item.originPrice}-${item.salePrice}`
                                );
                                let tempTotal = number.operation(`${tempPrice}*${item.quantity}`);
                                tempUseVoucherReduceAmount = +number.operation(
                                    `${tempUseVoucherReduceAmount}+${tempTotal}`
                                );
                            }
                        }
                        this.onLineShopCardList = res.data.cart.items;
                        this.useCardCount = tempUseCardCount;
                        this.useCardReduceAmount = tempUseCardReduceAmount;
                        this.useVoucherCount = tempUseVoucherCount;
                        this.useVoucherReduceAmount = tempUseVoucherReduceAmount;
                        this.totalCount = res.data.cart.quantity;
                        this.totalPay = res.data.cart.salePrice;
                        this.province = res.data.cart.saveAmount;
                    }
                });
        },
        // 获取卡券数量
        getCardCount() {
            this.$util.get(GET_USER_AVAILABLE_CARD_COUNT).then(res => {
                if (res.success) {
                    this.cardCount = res.data.cardCount;
                    this.voucherCount = res.data.voucherCount;
                }
            });
        },
        switchStatus() {
            this.openSpcialStatus = !this.openSpcialStatus;
        },
        // 获取配送费
        getDeliveryFee() {
            this.$util
                .post(GET_DELIVERY_FEE, {
                    storeCode: this.storeCode,
                    userAddressId: this.addressId,
                })
                .then(res => {
                    if (res.success) {
                        this.mtDeliveryFee = res.data.deliveryFee;
                    }
                });
        },
        // 获取收货地址
        getAddress() {
            this.$util.get(`${GET_ADDRESS_ITEM}/${this.addressId}`).then(res => {
                if (res.success) {
                    this.addressInfo = res.data;
                    if (!this.userMobile) {
                        this.userMobile = res.data.mobile;
                    }
                }
            });
        },
        // 获取用户手机号
        getUserMobile() {
            this.$util.get(GET_LAST_ORDER_MOBILE).then(res => {
                if (res.success) {
                    this.userMobile = res.data;
                }
            });
        },
        // 获取店铺信息
        getData() {
            this.$util
                .post(GET_STOP_INFO, {
                    storeCode: this.storeCode,
                })
                .then(res => {
                    if (res.success) {
                        this.mealStoreInfo.name = res.data.storeName;
                        this.mealStoreInfo.adress = res.data.address;
                    }
                });
        },
        // 增加加购
        plusNum(index) {
            if (this.specialTotalCount >= this.specialMaxQuantity) {
                return;
            }
            this.specialProductList[index].num++;
            this.setSpecialTotal();
        },
        // 减少加购
        reduceNum(index) {
            this.specialProductList[index].num--;
            this.setSpecialTotal();
        },
        // 渲染加购小计
        setSpecialTotal() {
            let _specialTotalCount = 0;
            let _specialTotalPay = 0;
            let _specialProvince = 0;
            for (let i = 0; i < this.specialProductList.length; i++) {
                const item = this.specialProductList[i];
                item.reducedPrice = Number(
                    number.operation(
                        `${item.num}*${number.operation(`${item.originalPrice}-${item.price}`)}`
                    )
                );
                _specialTotalCount += item.num;
                _specialTotalPay += Number(number.operation(`${item.num}*${item.price}`));
                _specialProvince += Number(number.operation(`${item.num}*${item.reducedPrice}`));
            }
            this.specialTotalCount = _specialTotalCount;
            this.specialTotalPay = _specialTotalPay;
            this.specialProvince = _specialProvince;
        },
        // 获取加购商品
        getSpecial() {
            if (this.mealDetail.length == 0) return;
            let items = [];
            for (let i = 0; i < this.mealDetail.length; i++) {
                const item = this.mealDetail[i];
                items.push({
                    productId: item.productId,
                    productType: item.type,
                    quantity: item.num,
                });
            }

            this.$util
                .post(GET_KFC_SPECIAL_PRODUCT, {
                    items: items,
                    storeCode: this.storeCode,
                })
                .then(res => {
                    if (res.success) {
                        this.specialMaxQuantity = res.data.canChooseProductQuantity;
                        for (let i = 0; i < res.data.comboSpecialProductList.length; i++) {
                            const item = res.data.comboSpecialProductList[i];
                            item.num = 0;
                        }
                        this.specialProductList = res.data.comboSpecialProductList;
                    }
                });
        },
        // 点餐要求切换
        AddQuest(name) {
            this.mealQuest[name] = !this.mealQuest[name];
        },
        // 去支付
        addCommit() {
            if (this.pageType == 0) {
                if (!mobileReg.test(this.userMobile) && !this.addressId) {
                    this.$toast('请输入正确的手机号码');
                    return;
                }
                this.dialogShow = true;
            } else {
                this.debounceSubmitOrder();
            }
        },
        // 关闭确认窗口
        closeConfirm() {
            this.dialogShow = false;
        },
        // 切换就餐方式
        chooseType(type) {
            this.mealType = type;
        },
        // 切换收货地址
        toSwitchAddress() {
            this.$util.pageJump(PAGE_ADDRESS_LIST, {
                type: 2,
            });
        },
        openSkuText(index) {
            if (this.mealDetail[index].skuOpenStatus) {
                this.mealDetail[index].skuOpenStatus = false;
            } else {
                this.mealDetail[index].skuOpenStatus = true;
            }
            this.$forceUpdate();
        },
        debounceSubmitOrder() {
            if (this.pageType == 0 || this.pageType == 2) {
                this.$util.debounce(this.submitCartOrder)();
            } else {
                this.$util.debounce(this.submitOrder)();
            }
        },
        // 使用优惠券回调
        couponUseCallback(data) {
            if (data.success) {
                this.getOnLineCart();
                this.getCardCount();
            }
        },
        // 创建购物车订单
        submitCartOrder() {
            if (this.orderDetail) {
                return payFun(this, this.orderDetail);
            }
            this.$util.eventStatistics({
                title: 'CreateOrder',
            });
            let requestData = {
                eatType: this.mealType,
                mobile: this.userMobile,
                storeCode: this.storeCode,
                userRemark: this.userRemark,
            };
            if (this.addressId && this.addressId != 0 && this.pageType != 2) {
                requestData.eatType = 3;
                requestData.userAddressId = this.addressId;
            }
            else{
                requestData.userAddressId = this.addressId;
            }
            this.$util.post('/api/kfcOrder/createOrder4Cart', requestData).then(res => {
                if (res.success) {
                    this.orderDetail = res.data;
                    this.payNextFunc(this.orderDetail);
                }
            });
        },
        // 创建订单
        submitOrder() {
            if (this.orderDetail) {
                return payFun(this, this.orderDetail);
            }
            this.$util.eventStatistics({
                title: 'CreateTakeoutOrder',
            });

            let requestUrl = CREATRE_OUT_BY_KFC_ORDER;
            this.$util
                .post(requestUrl, {
                    addressId: this.addressId,
                    storeCode: this.storeCode,
                    userRemark: this.userRemark,
                })
                .then(res => {
                    if (res.success) {
                        this.orderDetail = res.data;
                        this.payNextFunc(this.orderDetail);
                    }
                });
        },
        payNextFunc(data) {
            pay(data).then(payRes => {
                if (payRes.success && (payRes.source == 'WX' || payRes.source == 'BD_XCX')) {
                    this.$util.pageJump({
                        path: PAGE_ORDER_INFO,
                        query: {
                            orderNo: data.orderNo,
                            orderType: data.orderType,
                        },
                        config: {
                            jumpType: 1,
                        },
                    });
                }
            });
        },
        // 保存备注
        saveUserRemark() {
            this.userRemark = this.tempUserRemark;
            this.userRemarkDialogStatus = false;
        },
        // 添加备注
        addUserRemark(text) {
            this.tempUserRemark += text;
            this.tempUserRemark = this.tempUserRemark.substring(0, 50);
        },
    },
};
</script>
<style lang="less" scoped>
@import url('~@/lib/base.less');
@footHeight: .pxToRem(148) [];

.address-box {
    width: .pxToRem(710) [];
    background: white;
    border-radius: .pxToRem(30) [];
    margin: .pxToRem(20) [] auto 0;
    .address-info {
        padding: .pxToRem(30) [] .pxToRem(30) [] .pxToRem(0) [];
        .street {
            width: 100%;
            box-sizing: border-box;
            line-height: .pxToRem(36) [];
            font-size: .pxToRem(26) [];
            color: rgba(0, 0, 0, 0.9);
            font-weight: bold;
            text-indent: .pxToRem(36) [];
            &::before {
                content: ' ';
                position: absolute;
                top: .pxToRem(6) [];
                left: 0;
                width: .pxToRem(24) [];
                height: .pxToRem(24) [];
                background: url('~@/assets/icons/icon-location-map.png');
                background-size: cover;
            }
        }
        .user {
            margin-top: .pxToRem(4) [];
            font-size: .pxToRem(26) [];
            line-height: .pxToRem(36) [];
            color: rgba(0, 0, 0, 0.6);
        }
        .tip-1,
        .tip-2 {
            height: .pxToRem(40) [];
            line-height: .pxToRem(40) [];
            font-size: .pxToRem(28) [];
        }
        .tip-2 {
            padding-bottom: .pxToRem(30) [];
        }
        .icon-right {
            position: absolute;
            right: .pxToRem(20) [];
            top: 50%;
            transform: translateY(-50%) rotate(135deg);
            &::before {
                content: ' ';
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: .pxToRem(15) [];
                height: .pxToRem(3) [];
                background: #999999;
            }
            &::after {
                content: ' ';
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                width: .pxToRem(3) [];
                height: .pxToRem(15) [];
                background: #999999;
            }
        }
    }
    .msg-line {
        display: flex;
        justify-content: space-between;
        width: .pxToRem(710) [];
        margin: 0 auto;
        padding: .pxToRem(16) [] 0;
        .name {
            margin-left: .pxToRem(30) [];
            height: .pxToRem(40) [];
            line-height: .pxToRem(40) [];
            font-size: .pxToRem(24) [];
            font-weight: 600;
            color: rgba(0, 0, 0, 0.9);
        }
        .value {
            margin-right: .pxToRem(30) [];
            height: .pxToRem(40) [];
            line-height: .pxToRem(40) [];
            font-size: .pxToRem(24) [];
            color: @theme_color_first;
        }
    }
}
.adress {
    width: 100%;
    background: white;
    border-radius: 0 0 .pxToRem(40) [] .pxToRem(40) [];
    margin-bottom: .pxToRem(88) [];
    .kfc-name {
        text-align: center;
        line-height: .pxToRem(40) [];
        font-size: .pxToRem(40) [];
        color: rgba(0, 0, 0, 0.9);
        padding: .pxToRem(30) [] .pxToRem(80) [] .pxToRem(62) [] .pxToRem(80) [];
        font-weight: 600;
        box-sizing: border-box;
        &:after {
            content: ' ';
            .base-center-x;
            bottom: .pxToRem(10) [];
            .sq(32);
            background: url('~@/assets/icons/icon-location-map.png');
            background-size: cover;
        }
    }
    .adress-detail {
        text-align: center;
        line-height: .pxToRem(40) [];
        font-size: .pxToRem(26) [];
        color: rgba(0, 0, 0, 0.5);
        padding: 0 .pxToRem(80) [] .pxToRem(32) [] .pxToRem(80) [];
    }
    .tips {
        .base-center-x;
        bottom: .pxToRem(-65) [];
        width: 100%;
        text-align: center;
        height: .pxToRem(40) [];
        line-height: .pxToRem(40) [];
        font-size: .pxToRem(26) [];
        color: @theme_color_first;
    }
}

.box-shadow {
    box-shadow: .pxToRem(3) [] .pxToRem(3) [] .pxToRem(8) [] 0 rgba(0, 0, 0, 0.1);
}

.order-detail {
    width: .pxToRem(710) [];
    margin: 0 auto;
    margin-top: .pxToRem(20) [];
    border-radius: .pxToRem(30) [];
    background: white;
    .name {
        padding-left: .pxToRem(30) [];
        height: .pxToRem(88) [];
        line-height: .pxToRem(88) [];
        text-align: left;
        font-size: .pxToRem(30) [];
        font-weight: 600;
        color: rgba(0, 0, 0, 0.9);
    }
    .list {
        border-top: .pxToRem(1) [] solid rgba(0, 0, 0, 0.15);
        overflow: hidden;
        .order-single {
            display: flex;
            height: .pxToRem(140) [];
            line-height: .pxToRem(140) [];
            padding-left: .pxToRem(30) [];
            padding-right: .pxToRem(30) [];
            .img {
                .base-center-y;
                left: .pxToRem(30) [];
                max-width: .pxToRem(110) [];
                max-height: .pxToRem(80) [];
            }
            .title {
                .base-center-y;
                left: .pxToRem(160) [];
                right: .pxToRem(220) [];
                line-height: .pxToRem(42) [];
                font-weight: 600;
                .overflow(2);
            }
            .num {
                .base-center-y;
                left: .pxToRem(524) [];
                font-size: .pxToRem(26) [];
                height: .pxToRem(40) [];
                line-height: .pxToRem(40) [];
                color: rgba(0, 0, 0, 0.5);
            }
            .price {
                position: absolute;
                top: .pxToRem(30) [];
                right: .pxToRem(30) [];
                height: .pxToRem(40) [];
                line-height: .pxToRem(40) [];
                font-size: .pxToRem(30) [];
                font-weight: 600;
                &::before {
                    content: '¥';
                    position: absolute;
                    bottom: .pxToRem(1) [];
                    left: .pxToRem(-20) [];
                    font-weight: 400;
                    font-size: .pxToRem(24) [];
                    height: .pxToRem(36) [];
                    line-height: .pxToRem(36) [];
                    width: .pxToRem(24) [];
                    text-align: center;
                    color: rgba(0, 0, 0, 0.9);
                }
            }
            .original-price {
                position: absolute;
                bottom: .pxToRem(40) [];
                right: .pxToRem(30) [];
                height: .pxToRem(36) [];
                line-height: .pxToRem(36) [];
                font-size: .pxToRem(22) [];
                color: rgba(0, 0, 0, 0.3);
                text-decoration: line-through;
                .first-activity {
                    .base-center-y;
                    left: .pxToRem(-16) [];
                    white-space: nowrap;
                    transform: translate(-100%, -50%);
                }
            }
            &.has-sku {
                height: auto;
                .title {
                    top: .pxToRem(48) [];
                    .overflow(1);
                }
                .sku-box {
                    float: left;
                    margin-top: .pxToRem(72) [];
                    margin-bottom: .pxToRem(32) [];
                    margin-left: .pxToRem(130) [];
                    height: .pxToRem(36) [];
                    line-height: .pxToRem(36) [];
                    overflow: hidden;
                    background: rgba(0, 0, 0, 0.1);
                    max-width: .pxToRem(340) [];
                    .sku-item {
                        box-sizing: border-box;
                        padding: 0 .pxToRem(10) [];
                        font-size: .pxToRem(22) [];
                        .overflow(1);
                        display: flex;
                        .sku-product-name {
                            .overflow(1);
                        }
                        .sku-product-count {
                            flex: 1;
                            white-space: nowrap;
                        }
                    }
                    &.open {
                        height: auto;
                        overflow: unset;
                    }
                }
            }
            &.favour {
                .title {
                    p {
                        text-indent: .pxToRem(50) [];
                    }
                    &:before {
                        content: '惠';
                        text-indent: .pxToRem(0) [];
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: #d90209;
                        height: .pxToRem(40) [];
                        line-height: .pxToRem(40) [];
                        width: .pxToRem(40) [];
                        text-align: center;
                        border-radius: .pxToRem(4) [];
                        color: white;
                    }
                }
            }
            &.voucher {
                .title {
                    p {
                        text-indent: .pxToRem(50) [];
                    }
                    &:before {
                        content: '代';
                        text-indent: .pxToRem(0) [];
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: #d90209;
                        height: .pxToRem(40) [];
                        line-height: .pxToRem(40) [];
                        width: .pxToRem(40) [];
                        text-align: center;
                        border-radius: .pxToRem(4) [];
                        color: white;
                    }
                }
            }
        }
    }
    .coupon-line {
        width: 100%;
        height: .pxToRem(40) [];
        line-height: .pxToRem(40) [];
        border-top: .pxToRem(1) [] solid rgba(0, 0, 0, 0.15);
        padding: .pxToRem(24) [] 0;
        display: flex;
        justify-content: space-between;
        .title {
            padding-left: .pxToRem(90) [];
            font-size: .pxToRem(30) [];
            font-weight: 600;
            &::before {
                content: ' ';
                position: absolute;
                left: .pxToRem(30) [];
                top: 50%;
                transform: translateY(-50%);
                width: .pxToRem(44) [];
                height: .pxToRem(44) [];
                background: url('~@/assets/icons/icon-coupon.png');
                background-size: cover;
            }
        }
        .value {
            display: flex;
            padding-right: .pxToRem(16) [];
            align-items: center;
            .tip-use-coupon {
                height: .pxToRem(44) [];
                line-height: .pxToRem(44) [];
                font-size: .pxToRem(26) [];
                color: #865b22;
                background: #ffe5b5;
                padding: 0 .pxToRem(44) [] 0 .pxToRem(24) [];
                border-radius: .pxToRem(22) [];
                // &::before {
                //     content: '👉';
                //     position: absolute;
                //     left: .pxToRem(-12) [];
                //     transform: translateX(-100%);
                //     animation-name: finger;
                //     animation-duration: 0.3s;
                //     animation-iteration-count: infinite;
                // }
                @keyframes finger {
                    0% {
                        left: .pxToRem(-12) [];
                    }
                    50% {
                        left: .pxToRem(-24) [];
                    }
                    100% {
                        left: .pxToRem(-12) [];
                    }
                }
            }
            .tip-used-coupon {
                height: .pxToRem(44) [];
                line-height: .pxToRem(44) [];
                font-size: .pxToRem(30) [];
                color: #d80108;
                padding: 0 .pxToRem(44) [] 0 .pxToRem(24) [];
                border-radius: .pxToRem(22) [];
                font-weight: bold;
            }
            .tip-not-coupon {
                height: .pxToRem(44) [];
                line-height: .pxToRem(44) [];
                font-size: .pxToRem(26) [];
                color: rgb(153 153 153);
                background: rgb(244 244 244);
                padding: 0 .pxToRem(44) [] 0 .pxToRem(24) [];
                border-radius: .pxToRem(22) [];
            }
            .right {
                position: absolute;
                top: 50%;
                right: .pxToRem(24) [];
                transform: translateY(-50%) rotate(-45deg);
                width: .pxToRem(28) [];
                height: .pxToRem(28) [];
                &::before {
                    content: ' ';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    width: .pxToRem(15) [];
                    height: .pxToRem(3) [];
                    background: rgb(153, 153, 153);
                }
                &::after {
                    content: ' ';
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    width: .pxToRem(4) [];
                    height: .pxToRem(15) [];
                    background: rgb(153, 153, 153);
                }
            }
        }
    }
    .sum {
        border-top: .pxToRem(1) [] solid rgba(0, 0, 0, 0.15);
        line-height: .pxToRem(88) [];
        padding: .pxToRem(16) [] .pxToRem(30) [];
        text-align: right;
        box-sizing: border;
        .line {
            height: .pxToRem(56) [];
            line-height: .pxToRem(56) [];
            font-weight: 600;
            font-size: .pxToRem(26) [];
            display: flex;
            justify-content: flex-end;
        }
        .m-l-20 {
            margin-left: .pxToRem(20) [];
        }
        .num {
            color: @theme_color_first;
            font-size: .pxToRem(36) [];
            &::before {
                content: '¥';
                font-size: .pxToRem(24) [];
                width: .pxToRem(24) [];
                text-align: center;
            }
        }
        .integral {
            color: @theme_color_first;
            font-size: .pxToRem(36) [];
        }
    }
    &.special {
        margin-top: .pxToRem(10) [];
        .open-more-spcial {
            font-size: .pxToRem(26) [];
            height: .pxToRem(26) [];
            line-height: .pxToRem(26) [];
            padding: .pxToRem(30) [] 0;
            text-align: center;
            border-top: .pxToRem(2) [] solid #f2f2f2;
            span {
                padding-right: .pxToRem(34) [];
                &:after {
                    content: ' ';
                    position: absolute;
                    top: 50%;
                    right: 0;
                    margin-top: .pxToRem(-6.5) [];
                    background: url('~@/assets/img/spcial-more.png');
                    background-size: contain;
                    width: .pxToRem(24) [];
                    height: .pxToRem(13) [];
                }
            }
            &.open span::after {
                transform: rotate(180deg);
            }
        }
        .list {
            .order-single {
                height: .pxToRem(140) [];
                padding-left: .pxToRem(10) [];
                .reduced {
                    position: absolute;
                    bottom: .pxToRem(6) [];
                    right: .pxToRem(30) [];
                    color: #aba31b;
                    font-size: .pxToRem(20) [];
                    line-height: .pxToRem(26) [];
                    height: .pxToRem(26) [];
                }
                .icon-click {
                    .base-center-y;
                    right: .pxToRem(30) [];
                    height: .pxToRem(56) [];
                    .btn-reduce {
                        position: absolute;
                        top: 0;
                        left: 0;
                        .sq(56);
                        z-index: 1;
                    }
                    .quantity {
                        padding: 0 .pxToRem(74) [];
                        height: .pxToRem(56) [];
                        line-height: .pxToRem(56) [];
                    }
                    .btn-plus {
                        position: absolute;
                        top: 0;
                        right: 0;
                        .sq(56);
                        z-index: 1;
                        &.no-add {
                            opacity: 0.4;
                        }
                    }
                }
                .img {
                    .base-center-y;
                    max-width: .pxToRem(110) [];
                    max-height: .pxToRem(80) [];
                }
                .title {
                    font-weight: 400;
                }
                .price {
                    left: .pxToRem(160) [];
                    top: unset;
                    bottom: .pxToRem(30) [];
                    font-size: .pxToRem(30) [];
                    color: @theme_color_first;
                    font-size: .pxToRem(30) [];
                    width: max-content;
                    white-space: nowrap;
                    &::before {
                        display: inline-block;
                        position: relative;
                        left: unset;
                        bottom: unset;
                        color: @theme_color_first;
                        font-size: .pxToRem(30) [];
                        font-weight: 600;
                    }
                    .original-price {
                        position: absolute;
                        right: .pxToRem(-10) [];
                        top: .pxToRem(5) [];
                        white-space: nowrap;
                        transform: translateX(100%);
                        font-size: .pxToRem(22) [];
                        height: .pxToRem(36) [];
                        line-height: .pxToRem(36) [];
                    }
                }
            }
        }
    }
}

.user-mobile-line {
    width: .pxToRem(710) [];
    margin: 0 auto;
    margin-top: .pxToRem(10) [];
    height: .pxToRem(90) [];
    border-radius: .pxToRem(30) [];
    padding-bottom: .pxToRem(10) [];
    background: white;
    display: flex;
    .head {
        height: .pxToRem(90) [];
        line-height: .pxToRem(90) [];
        font-size: .pxToRem(30) [];
        color: rgba(0, 0, 0, 0.9);
        font-weight: 600;
        padding-left: .pxToRem(30) [];
        width: .pxToRem(200) [];
        &:before {
            content: '仅用于接收取餐码，请正确填写';
            position: absolute;
            bottom: .pxToRem(0) [];
            left: .pxToRem(30) [];
            font-size: .pxToRem(22) [];
            line-height: .pxToRem(24) [];
            font-weight: 400;
            white-space: nowrap;
            color: rgba(0, 0, 0, 0.6);
        }
    }
    .user-moblie {
        flex: 1;
        text-align: right;
        background: rgba(0, 0, 0, 0);
        font-size: .pxToRem(28) [];
        margin-top: .pxToRem(5) [];
        padding-right: .pxToRem(30) [];
    }
}
.meal-type {
    width: .pxToRem(710) [];
    height: .pxToRem(200) [];
    background-color: #fff;
    margin: 0 auto;
    margin-top: .pxToRem(10) [];
    border-radius: .pxToRem(30) [];
    .head {
        height: .pxToRem(40) [];
        line-height: .pxToRem(40) [];
        padding-top: .pxToRem(24) [];
        padding-left: .pxToRem(30) [];
        font-size: .pxToRem(28) [];
        color: #363636;
        font-weight: bold;
    }
    .block {
        height: .pxToRem(64) [];
        padding: 0 .pxToRem(30) [];
        margin-top: .pxToRem(30) [];
        .jc-sb;
        .btn {
            width: .pxToRem(315) [];
            height: .pxToRem(64) [];
            box-sizing: border-box;
            border: .pxToRem(1) [] solid #313131;
            border-radius: .pxToRem(8) [];
            display: flex;
            justify-content: center;
            align-items: center;
            .img {
                .sq(44);
            }
            .text {
                height: .pxToRem(64) [];
                line-height: .pxToRem(64) [];
                font-size: .pxToRem(26) [];
                color: rgba(0, 0, 0, 0.5);
                margin-left: .pxToRem(20) [];
            }
            .select {
                display: none;
            }
            &.cur {
                border: .pxToRem(1) [] solid @theme_color_first;
                background: #fdf0f0;
                .select {
                    display: block;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: .pxToRem(39) [];
                    height: .pxToRem(26) [];
                }
                .text {
                    color: @theme_color_first;
                }
            }
        }
    }
}
.user-remark {
    width: .pxToRem(710) [];
    margin: 0 auto;
    margin-top: .pxToRem(10) [];
    height: .pxToRem(90) [];
    border-radius: .pxToRem(30) [];
    background: white;
    display: flex;
    .head {
        height: .pxToRem(90) [];
        line-height: .pxToRem(90) [];
        font-size: .pxToRem(30) [];
        color: rgba(0, 0, 0, 0.9);
        font-weight: 600;
        padding-left: .pxToRem(30) [];
        width: .pxToRem(200) [];
    }
    .value {
        flex: 1;
        height: .pxToRem(90) [];
        line-height: .pxToRem(90) [];
        text-align: right;
        font-size: .pxToRem(24) [];
        color: rgba(0, 0, 0, 0.9);
        padding-right: .pxToRem(56) [];
        .overflow(1);
    }
    .right {
        position: absolute;
        top: 49%;
        right: .pxToRem(24) [];
        transform: translateY(-50%) rotate(-45deg);
        width: .pxToRem(28) [];
        height: .pxToRem(28) [];
        &::before {
            content: ' ';
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: .pxToRem(15) [];
            height: .pxToRem(3) [];
            background: rgb(153, 153, 153);
        }
        &::after {
            content: ' ';
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: .pxToRem(4) [];
            height: .pxToRem(15) [];
            background: rgb(153, 153, 153);
        }
    }
}

.cart-box {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 200;
    .cart-line {
        height: .pxToRem(100) [];
        width: .pxToRem(702) [];
        margin: 0 auto .pxToRem(48) [];
        .coupon-box {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: .pxToRem(144) [];
            margin-left: .pxToRem(50) [];
            background: #333333;
            transform: skewX(-10deg);
            border-radius: 0 .pxToRem(12) [] .pxToRem(12) [] 0;
            .content {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                transform: skewX(10deg);
                .title {
                    line-height: .pxToRem(30) [];
                    font-size: .pxToRem(28) [];
                    font-weight: 600;
                    color: white;
                }
                .coupon-count {
                    line-height: .pxToRem(26) [];
                    font-size: .pxToRem(24) [];
                    margin-top: .pxToRem(8) [];
                    color: white;
                    font-weight: 600;
                }
            }

            &::before {
                content: ' ';
                position: absolute;
                left: .pxToRem(-50) [];
                top: 0;
                bottom: 0;
                width: .pxToRem(60) [];
                height: 100%;
                background: #333333;
                transform: skewX(10deg);
                border-radius: .pxToRem(50) [] 0 0 .pxToRem(50) [];
            }
        }
        .cart-price {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            left: .pxToRem(204) [];
            background-color: #d90209;
            transform: skewX(-10deg);
            margin-right: .pxToRem(50) [];
            border-radius: .pxToRem(12) [] 0 0 .pxToRem(12) [];
            transition-property: left, border-radius;
            transition-duration: 0.1s;
            .content {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                transform: skewX(10deg);
                .price-box {
                    position: absolute;
                    left: 0;
                    right: .pxToRem(100) [];
                    top: 0;
                    bottom: 0;
                    height: 100%;
                    margin-left: .pxToRem(12) [];
                    padding-left: .pxToRem(100) [];
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .product-count {
                        position: absolute;
                        top: .pxToRem(8) [];
                        left: .pxToRem(70) [];
                        background: #333333;
                        color: white;
                        width: .pxToRem(40) [];
                        text-align: center;
                        height: .pxToRem(40) [];
                        line-height: .pxToRem(40) [];
                        border-radius: 100%;
                        font-size: .pxToRem(24) [];
                        font-weight: 600;
                    }
                    .price {
                        margin-left: .pxToRem(16) [];
                        line-height: .pxToRem(48) [];
                        font-size: .pxToRem(42) [];
                        font-weight: 600;
                        color: white;
                        display: flex;
                        align-items: baseline;
                        .monery-discount {
                            display: none;
                            margin-left: .pxToRem(12) [];
                            height: .pxToRem(32) [];
                            line-height: .pxToRem(32) [];
                            font-size: .pxToRem(24) [];
                        }
                    }
                    .delivery-fee {
                        margin-left: .pxToRem(16) [];
                        line-height: .pxToRem(28) [];
                        font-size: .pxToRem(26) [];
                        font-weight: 600;
                        color: white;
                        .line-through {
                            text-decoration: line-through;
                        }
                    }
                    &::before {
                        content: ' ';
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: .pxToRem(100) [];
                        height: .pxToRem(100) [];
                        background: url('~@/assets/icons/icon-cart.webp');
                        background-repeat: no-repeat;
                        background-size: cover;
                    }
                }
                .submit-btn {
                    position: absolute;
                    right: .pxToRem(-20) [];
                    top: 0;
                    bottom: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .title {
                        line-height: .pxToRem(38) [];
                        font-size: .pxToRem(36) [];
                        color: white;
                        font-weight: 600;
                    }
                    .tip {
                        line-height: .pxToRem(26) [];
                        font-size: .pxToRem(24) [];
                        color: white;
                        font-weight: 600;
                        text-align: center;
                    }
                }
                .no-select-product-tip {
                    line-height: .pxToRem(100) [];
                    margin-left: .pxToRem(112) [];
                    font-size: .pxToRem(36) [];
                    color: white;
                    font-size: 600;
                }
            }
            &::before {
                content: ' ';
                position: absolute;
                right: .pxToRem(-50) [];
                top: 0;
                bottom: 0;
                width: .pxToRem(60) [];
                height: 100%;
                background-color: #d90209;
                transform: skewX(10deg);
                border-radius: 0 .pxToRem(50) [] .pxToRem(50) [] 0;
            }
        }
    }
    &.open-cart {
        .coupon-box {
            left: .pxToRem(2) [];
            top: .pxToRem(2) [];
            bottom: .pxToRem(2) [];
        }
        .cart-line {
            .cart-price {
                left: 0;
                transform: skewX(0deg);
                border-radius: .pxToRem(50) [] 0 0 .pxToRem(50) [];
                .content {
                    transform: skewX(0deg);
                    .price-box {
                        .price {
                            .monery-discount {
                                display: unset;
                            }
                        }
                    }
                }
                &::before {
                    transform: skewX(0deg);
                }
            }
        }
    }
    &.not-product {
        .cart-line {
            .cart-price {
                background-color: #979797;
                .content {
                    .price-box {
                        .price {
                            display: none;
                        }
                        .delivery-fee {
                            display: none !important;
                        }
                    }
                    .submit-btn {
                        display: none;
                    }
                }
                &::before {
                    background-color: #979797;
                }
            }
        }
    }
}

.coupon-card-dialog {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 500;
    background: rgba(0, 0, 0, 0.65);
    .content {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 80vh;
        background: #f2f2f2;
        border-radius: .pxToRem(20) [] .pxToRem(20) [] 0 0;
        overflow: hidden;
        .title {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: .pxToRem(72) [];
            line-height: .pxToRem(72) [];
            background: white;
            font-size: .pxToRem(28) [];
            font-weight: 600;
            padding-left: .pxToRem(20) [];
        }
        .close {
            position: absolute;
            right: .pxToRem(12) [];
            top: .pxToRem(4) [];
            width: .pxToRem(64) [];
            height: .pxToRem(64) [];
            transform: rotate(-45deg);
            // background: red;
            &::before {
                content: ' ';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                height: .pxToRem(4) [];
                width: .pxToRem(32) [];
                background: #333333;
            }
            &::after {
                content: ' ';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                height: .pxToRem(32) [];
                width: .pxToRem(4) [];
                background: #333333;
            }
        }
        .coupon-card {
            position: absolute;
            top: .pxToRem(72) [];
            left: 0;
            right: 0;
            bottom: 0;
            overflow: scroll;
        }
    }
}

.other-request {
    overflow: hidden;
    height: .pxToRem(280) [];
    background-color: #fff;
    padding-left: .pxToRem(30) [];
    border-bottom: .pxToRem(20) [] solid #f5f5f5;
}

.other-request .head {
    height: .pxToRem(82) [];
    line-height: .pxToRem(86) [];
    overflow: hidden;
    font-size: .pxToRem(28) [];
    color: #363636;
}

.other-request .list {
    height: .pxToRem(80) [];
}

.other-request .list .single {
    float: left;
    padding: 0 .pxToRem(18) [];
    height: .pxToRem(62) [];
    line-height: .pxToRem(62) [];
    font-size: .pxToRem(26) [];
    color: #7e7e7e;
    margin-right: .pxToRem(30) [];
    border: 1px solid #d4d4d4;
    border-radius: .pxToRem(5) [];
}

.other-request .list .single.cur {
    color: #ba3c3f;
    border-color: #ba3c3f;
}

.fixed-pay {
    position: fixed;
    height: .pxToRem(88) [];
    line-height: .pxToRem(88) [];
    width: 100%;
    bottom: 0;
    left: 0;
    background: #000000;
    .total {
        position: absolute;
        left: .pxToRem(30) [];
        top: .pxToRem(24) [];
        height: .pxToRem(44) [];
        line-height: .pxToRem(44) [];
        color: white;
        font-size: .pxToRem(28) [];
        display: flex;
        justify-content: center;
        align-items: center;
        .price {
            margin-top: .pxToRem(-3) [];
            font-size: .pxToRem(40) [];
            padding-left: .pxToRem(28) [];
            &:before {
                content: '¥';
                position: absolute;
                left: 0;
                bottom: .pxToRem(-3) [];
                width: .pxToRem(28) [];
                text-align: center;
                font-size: .pxToRem(28) [];
            }
        }
        .msg {
            height: .pxToRem(27) [];
            line-height: .pxToRem(27) [];
            font-size: .pxToRem(20) [];
            margin-left: .pxToRem(20) [];
            margin-top: .pxToRem(6) [];
        }
    }
    .btn {
        position: absolute;
        top: .pxToRem(14) [];
        right: .pxToRem(30) [];
        width: .pxToRem(150) [];
        text-align: center;
        height: .pxToRem(60) [];
        line-height: .pxToRem(60) [];
        border-radius: .pxToRem(30) [];
        background: @theme_color_first;
        font-size: .pxToRem(24) [];
        color: #ffffff;
    }
}

.fix-lary {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 300;
    .fix-win {
        .base-center;
        width: .pxToRem(650) [];
        background-color: #fff;
        border-radius: .pxToRem(40) [];
        .head {
            width: 100%;
            height: .pxToRem(135) [];
            background: url('~@/assets/images/confirm-order-bg.png');
            background-size: cover;
            .title {
                .base-center-x;
                top: .pxToRem(40) [];
                font-size: .pxToRem(34) [];
                height: .pxToRem(36) [];
                line-height: .pxToRem(36) [];
                color: white;
                font-weight: 600;
            }
            .circle {
                .base-center-x;
                bottom: .pxToRem(-40) [];
                .sq(80);
                background: white;
                border: .pxToRem(2) [] solid @theme_color_first;
                border-radius: 100%;
                &:before {
                    content: ' ';
                    .base-center;
                    .sq(32);
                    background: url('~@/assets/icons/icon-location-map.png');
                    background-size: cover;
                }
            }
        }
        .shop-name {
            margin-top: .pxToRem(58) [];
            line-height: .pxToRem(60) [];
            width: 100%;
            text-align: center;
            color: rgba(0, 0, 0, 0.9);
            font-weight: 600;
            font-size: .pxToRem(40) [];
            padding: 0 .pxToRem(50) [];
            box-sizing: border-box;
        }
        .shop-address {
            margin-top: .pxToRem(10) [];
            line-height: .pxToRem(40) [];
            width: 100%;
            text-align: center;
            color: rgba(0, 0, 0, 0.5);
            font-size: .pxToRem(26) [];
            padding: 0 .pxToRem(50) [];
            padding-bottom: .pxToRem(45) [];
            box-sizing: border-box;
        }
        .tip {
            width: 100%;
            padding: 0 .pxToRem(50) [];
            padding-top: .pxToRem(30) [];
            line-height: .pxToRem(40) [];
            color: rgba(0, 0, 0, 0.5);
            font-size: .pxToRem(26) [];
            text-align: center;
            box-sizing: border-box;
            .important {
                color: #d90209;
            }
            &:before {
                content: ' ';
                position: absolute;
                top: 0;
                left: .pxToRem(50) [];
                right: .pxToRem(50) [];
                height: .pxToRem(1) [];
                background: rgba(0, 0, 0, 0.15);
            }
        }
        .btn-line {
            .jc-sb;
            width: 100%;
            height: .pxToRem(80) [];
            padding: 0 .pxToRem(50) [];
            margin-top: .pxToRem(52) [];
            margin-bottom: .pxToRem(30) [];
            box-sizing: border-box;
            .btn {
                width: .pxToRem(210) [];
                height: .pxToRem(80) [];
                line-height: .pxToRem(80) [];
                text-align: center;
                border: .pxToRem(1) [] solid #313131;
                border-radius: .pxToRem(8) [];
                box-sizing: border-box;
                font-size: .pxToRem(28) [];
                color: rgba(0, 0, 0, 0.9);
                &.pay {
                    border: .pxToRem(1) [] solid @theme_color_first;
                    background: @theme_color_first;
                    color: white;
                }
            }
        }
    }
}

.user-remark-dialog {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 500;
    background: rgba(0, 0, 0, 0.65);
    .content {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: white;
        border-radius: .pxToRem(12) [] .pxToRem(12) [] 0 0;
        .title {
            width: 100%;
            height: .pxToRem(100) [];
            line-height: .pxToRem(100) [];
            text-align: left;
            padding-left: .pxToRem(24) [];
            font-size: .pxToRem(32) [];
            border-bottom: .pxToRem(1) [] solid rgba(0, 0, 0, 0.1);
        }
        .close {
            position: absolute;
            top: .pxToRem(24) [];
            right: .pxToRem(30) [];
            width: .pxToRem(50) [];
            height: .pxToRem(50) [];
            background: rgba(0, 0, 0, 0.1);
            border-radius: 100%;
            &:before {
                content: ' ';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(45deg);
                width: .pxToRem(2) [];
                height: .pxToRem(24) [];
                background: rgba(0, 0, 0, 0.9);
            }

            &:after {
                content: ' ';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(-45deg);
                width: .pxToRem(2) [];
                height: .pxToRem(24) [];
                background: rgba(0, 0, 0, 0.9);
            }
        }

        .textarea {
            display: block;
            width: .pxToRem(702) [];
            height: .pxToRem(160) [];
            line-height: .pxToRem(48) [];
            margin: 0 auto;
            border: none;
            background: rgba(0, 0, 0, 0.05);
            margin-top: .pxToRem(20) [];
            padding: .pxToRem(10) [];
            font-size: .pxToRem(26) [];
            box-sizing: border-box;
            border-radius: .pxToRem(8) [];
        }
        .count {
            position: absolute;
            bottom: .pxToRem(4) [];
            right: .pxToRem(40) [];
            font-size: .pxToRem(20) [];
            color: rgba(0, 0, 0, 0.6);
        }
        .quick-box {
            width: .pxToRem(702) [];
            margin: 0 auto;
            margin-top: .pxToRem(20) [];
            .quick-box-title {
                font-size: .pxToRem(24) [];
                color: rgba(0, 0, 0, 0.5);
            }
            .quick-list {
                margin-top: .pxToRem(12) [];
                .quick-item {
                    display: inline-block;
                    height: .pxToRem(48) [];
                    line-height: .pxToRem(48) [];
                    padding: 0 .pxToRem(12) [];
                    border: .pxToRem(1) [] solid rgba(0, 0, 0, 0.2);
                    margin-right: .pxToRem(16) [];
                    margin-bottom: .pxToRem(16) [];
                    border-radius: .pxToRem(8) [];
                }
            }
        }
        .btn-save {
            width: .pxToRem(702) [];
            text-align: center;
            margin: 0 auto;
            height: .pxToRem(80) [];
            line-height: .pxToRem(80) [];
            background: @theme_color_first;
            font-size: .pxToRem(28) [];
            color: white;
            border-radius: .pxToRem(16) [];
            margin-top: .pxToRem(40) [];
            margin-bottom: .pxToRem(20) [];
        }
    }
}

.padding-bottom {
    width: 100%;
    height: calc(@footHeight + .pxToRem(20) []);
}
</style>
<style lang="less" scoped>
@import url('~@/lib/base.less');
.show-original-price {
    .order-detail {
        .list .order-single {
            .title {
                right: .pxToRem(280) [];
            }
            .num {
                left: .pxToRem(464) [];
            }
        }
        .price {
            &:after {
                content: '到手价';
                position: absolute;
                top: 0;
                left: .pxToRem(-20) [];
                white-space: nowrap;
                transform: translateX(-100%);
                font-size: .pxToRem(24) [];
            }
        }
    }

    .fixed-pay {
        .btn {
            top: 0;
            bottom: 0;
            right: 0;
            border-radius: 0;
            width: unset;
            height: unset;
            line-height: unset;
            padding-left: .pxToRem(20) [];
            padding-right: .pxToRem(20) [];
            .title {
                margin-top: .pxToRem(8) [];
                margin-right: .pxToRem(8) [];
                font-size: .pxToRem(36) [];
                color: white;
                height: .pxToRem(44) [];
                line-height: .pxToRem(44) [];
            }
            .text {
                height: .pxToRem(32) [];
                line-height: .pxToRem(32) [];
                font-size: .pxToRem(24) [];
            }
        }
    }
}
</style>
